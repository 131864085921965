import * as api from "@bakkt/api";
import { IDatasource, IGetRowsParams } from "ag-grid-community";
import { AxiosPromise } from "axios";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";
import { Filter } from "../../api/gen/api/api";

dayjs.extend(UTC);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default abstract class PagedDataSource implements IDatasource {
  content: string;
  id: number;
  rowCount: number = undefined;
  externalFilters: Filter[] = [];
  constructor(content: string) {
    this.content = content;
    this.id = Math.random();
  }

  async getRows(params: IGetRowsParams) {
    // convert filter model
    // convert sort model
    // delegate to implementation for query and then invoke callback.
    const searchQuery = {} as api.SearchParameters;
    searchQuery.pageSize = params.endRow - params.startRow;
    searchQuery.pageNumber = Math.ceil(params.endRow / searchQuery.pageSize) - 1;
    if (this.content) {
      searchQuery.searchText = this.content;
    }

    searchQuery.filter = [...this.toServerFilter(params.filterModel), ...this.externalFilters];
    searchQuery.sort = this.toServerSort(params.sortModel);
    const resp = await this.query(searchQuery);
    this.rowCount = resp.total;
    params.successCallback(resp.rows, resp.total);
  }
  abstract query(searchQuery: api.SearchParameters): Promise<{ rows: any[]; total: number }>;

  toServerSort(agSortModel: any[]): api.SortBy[] {
    return agSortModel.map((sort) => ({ fieldName: sort.colId, sortOrder: sort.sort }));
  }
  toServerFilter(agFilterModel: any): api.Filter[] {
    return Object.keys(agFilterModel).map((param) => {
      // only allow a single filter per parameter for now.  This is because we have an implied AND condition on the server side.  Need to rethink
      // request data structure if we want to support multiple.  If we enable the and/or functionality, i think this actually brings back an array.
      const val: any = agFilterModel[param];

      let fieldValues = [val.filter];

      if (val.filterTo) {
        fieldValues.push(val.filterTo);
      }
      if (val.filterType === "set") {
        fieldValues = val.values;
        return { fieldName: param, fieldValues, searchCriteria: api.FilterSearchCriteriaEnum.IN };
      } else if (val.filterType === "date") {
        const type = val.type && val.type.toUpperCase();
        // eslint-disable-next-line
        const searchCriteria = (DateFilterSearchCriteriaEnum as any)[type];
        fieldValues = [];
        if (type === "INRANGE") {
          if (val.dateFrom) fieldValues.push(dayjs(val.dateFrom).startOf("day").format());
          if (val.dateTo) fieldValues.push(dayjs(val.dateTo).endOf("day").format());
        } else if (type === "LESSTHAN") {
          if (val.dateFrom) fieldValues.push(dayjs(val.dateFrom).startOf("day").format());
        } else if (type === "GREATERTHAN") {
          if (val.dateFrom) fieldValues.push(dayjs(val.dateFrom).endOf("day").format());
        } else if (type === "EQUALS") {
          if (val.dateFrom) fieldValues.push(dayjs(val.dateFrom).startOf("day").format());
          if (val.dateFrom) fieldValues.push(dayjs(val.dateFrom).endOf("day").format());
        }
        return { fieldName: param, fieldValues, searchCriteria };
      } else {
        const type = val.type && val.type.toUpperCase();
        const searchCriteria = (api.FilterSearchCriteriaEnum as any)[type];
        return { fieldName: param, fieldValues, searchCriteria };
      }
    });
  }
}

enum DateFilterSearchCriteriaEnum {
  LESSTHAN = "BEFORE",
  GREATERTHAN = "AFTER",
  INRANGE = "BETWEEN",
  EQUALS = "BETWEEN",
  NOT_EQUALS = "BETWEEN",
}

export type RequestHandler<T> = (request: api.SearchParameters) => AxiosPromise<any>;
export class ApiDataSource<T> extends PagedDataSource {
  apiHandler: RequestHandler<T>;
  onRowsLoad?: (rowCount: number) => void;
  constructor(apiHandler: RequestHandler<T>) {
    super("");
    this.apiHandler = apiHandler;
  }

  async query(request: api.SearchParameters): Promise<{ rows: any[]; total: number; isLastPage?: boolean }> {
    const response = await this.apiHandler(request);
    if (!response || !response.data || !response.data.payload) {
      return { rows: [], total: 0, isLastPage: true };
    }
    if (this.onRowsLoad) {
      this.onRowsLoad(response.data.payload.totalElements || 0);
    }
    return {
      rows: response.data.payload.content || [],
      total: response.data.payload.totalElements || 0,
      isLastPage: response.data.payload.size === (request.pageNumber || 0 + 1),
    };
  }
}
